export enum EDataType {
  // fundamentals
  CADASTRE = 'cadastre',
  TERRAIN = 'terrain',
  TREES = 'trees',
  SATELLITE_IMAGES = 'satelliteImages',
  EXISTING_BUILDINGS_3D = 'existingBuildings3D',
  EXISTING_BUILDINGS_REGISTER = 'existingBuildingsRegister',
  MUNICIPALITY_GEOMETRY = 'municipalityGeometry',
  LAND_REGISTER_AND_SERVITUDES = 'landRegisterAndServitudes',
  // restrictions
  LAW_ZONES = 'lawZones',
  LAW_PLANNING_ZONES = 'lawPlanningZones',
  SPECIAL_BUILDING_PLANS = 'specialBuildingPlans',
  CONSTRUCTION_LINES = 'constructionLines',
  FOREST_BORDERS = 'forestBorders',
  FOREST_DISTANCE_LINES = 'forestDistanceLines',
  WATER_BORDERS = 'waterBorders',
  WATER_DISTANCE_LINES = 'waterDistanceLines',
  CONTAMINATION = 'contamination',
  HAZARDS = 'hazards',
  PROTECTED_SITES_ISOS = 'protectedSitesIsos',
  HERITAGE_AREAS = 'heritageAreas',
  HERITAGE_OBJECTS = 'heritageObjects',
  PROTECTED_LANDSCAPE_FEATURES = 'protectedLandscapeFeatures',
  ARCHAEOLOGY = 'archaeology',
  AIRPORTS_CONSTRUCTION_LINES = 'airportsConstructionLines',
  AIRPORTS_BOUNDARY_AREA = 'airportsBoundaryArea',
  AIRPORTS_PLANNING_ZONES = 'airportsPlanningZones',
  AIRPORTS_SAFETY_ZONES = 'airportsSafetyZones',
  HIGH_VOLTAGE_LINES = 'highVoltageLines',
  HIGH_VOLTAGE_LINES_PLANS = 'highVoltageLinesPlans',
  HIGHWAYS_CONSTRUCTION_LINES = 'highwaysConstructionLines',
  HIGHWAYS_PLANNING_ZONES = 'highwaysPlanningZones',
  RAILWAYS_CONSTRUCTION_LINES = 'railwaysConstructionLines',
  RAILWAYS_PLANNING_ZONES = 'railwaysPlanningZones',
  // law
  FEDERAL_LAW = 'federalLaw',
  FEDERAL_PARAMETERS = 'federalParameters',
  CANTONAL_LAW = 'cantonalLaw',
  CANTONAL_PARAMETERS = 'cantonalParameters',
  MUNICIPALITY_LAW = 'municipalityLaw', // not in excel
  MUNICIPALITY_PARAMETERS = 'municipalityParameters', // not in excel
  MUNICIPAL_LAW_FILTER = 'municipalLawFilter',
  // noise
  TRAIN_NOISE_OVERVIEW = 'trainNoiseOverview', // TODO: old train noise
  CAR_NOISE_OVERVIEW = 'carNoiseOverview', // TODO: old car noise
  TRAIN_NOISE = 'trainNoise',
  CAR_NOISE = 'carNoise',
  AERIAL_NOISE = 'aerialNoise',
  // planning base
  SERVICE_LINES = 'serviceLines',
  PUBLIC_TRANSPORT_QUALITY = 'publicTransportQuality',
}

export enum EDataDateSpecificity {
  YEAR = 'year',
  MONTH = 'month',
  DAY = 'day',
}

export enum EDataQualityIndex {
  MISSING = 0,
  LOW = 1,
  MEDIUM = 2,
  HIGH = 3,
}
